import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const pagesVeirekkverk = ({ pageContext }) => {
  return (
    <Layout wider>
      <Seo title={pageContext?.data?.title} />

      <section className="produkter-single">
        <div className="container-fluid wider">
          <div className="row">
            <div className="col-md-5">
              <img
                className="img-fluid"
                src={
                  pageContext?.data?.data?.featuredImage?.node?.localFile
                    ?.publicURL
                }
                alt={pageContext?.data?.title}
              />
            </div>
            <div className="col-md-7">
              <div className="produkter-single-col-2">
                <h1>
                  {
                    <div
                      dangerouslySetInnerHTML={{
                        __html: pageContext?.data?.title,
                      }}
                    />
                  }
                </h1>
                <div
                  className="produkter-single-excerpt"
                  dangerouslySetInnerHTML={{
                    __html: pageContext?.data?.data?.content,
                  }}
                />
                {pageContext?.data?.data?.acfProduct?.externalUrl && (
                  <div className="produkter-single--action">
                    <a
                      href={pageContext?.data?.data?.acfProduct?.externalUrl}
                      className="btn_custom"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Les mer
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default pagesVeirekkverk
